
.rly_button {
  padding: 10px 36px;
  background-image: linear-gradient(103deg, rgb(241, 72, 11), rgb(255, 216, 0));
  border: none;
  color: white;
  height: 48px;
  min-width: 212px;
  margin-top: 10px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: bold;
}

.rly_link_button {
  color:rgb(241, 72, 11);
}
