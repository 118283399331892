@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat&display=swap');

.d-flex {
  display: flex;
}
.text-white {
  color: white;
}
.jc-center {
  justify-content: center;
}
.ai-center {
  align-items: center;
}

html {
  background-color: black;
}

#root {
  width: 100vw;
  background-color: black;
}
.App {
  background-color: black;
  text-align: center;
  min-width: 240px;
  max-width: 1280px;
  margin: auto;
  padding: 0px 10px 24px 10px;

  color: white;
  font-family: 'Montserrat';
}

h1,h2,h3 {
  color: white;
  margin: 0;
  font-family: 'Montserrat';
}

h1 {
  font-size: 28pt;
}

/* Ensure we import the solana provided CSS after all our custom styles
They do some slightly janky stuff to make their modal work */
@import '@solana/wallet-adapter-react-ui/styles.css';