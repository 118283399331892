.faq_container {
  text-align: left;
  max-width: 720px;
  margin: auto;
}

.faq_title {
  margin-top: 12px;
  font-size: 14pt;
  cursor: pointer;
}

.faq_expand_indicator {
  font-size: 28pt;
  margin-right: 12px;
}

.faq_description {
  margin-left: 16px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.faq_description p {
  margin-top: 12px;
  overflow-wrap: break-word;
}